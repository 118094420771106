import './Header.css';

const SocialMediaLinks = ({header=false}) =>{
 return <>
 <ul className={`footer-social-media-links ${header ? "header-responsive":""}`}>
                <li className="footer-social-media-link">
                    <a target="_blank" href="https://twitter.com/GameWagrs"><i className="fa-brands fa-twitter f-20"></i></a>
                  </li>
                  <li className="footer-social-media-link">
                    <a target="_blank" href="https://www.instagram.com/gamewagrs/"><i className="fa-brands fa-instagram f-20"></i></a>
                  </li>
                  <li className="footer-social-media-link">
                    <a target="_blank" href=" https://www.youtube.com/@gamewagrs"><i className="fa-brands fa-youtube f-20"></i></a>
                  </li>
                  <li className="footer-social-media-link">
                    <a target="_blank" href="https://www.tiktok.com/@duelcardsllc"><i className="fa-brands fa-tiktok f-20"></i></a>
                  </li>
                  <li className="footer-social-media-link">
                    <a target="_blank" href="https://discord.gg/NFCcznxpyZ"><i className="fa-brands fa-discord f-20"></i></a>
                  </li>
                </ul>
 </>
}
export default SocialMediaLinks