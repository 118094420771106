import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import './Contactus.css';
import axios from "axios";
import { errorToast, successToast } from "../constants/ToastNotifications";

const Contactus = () => {
  const defaultValue = { name: "", email: "", subject: "", message: "" }

  const recaptchaRef = React.createRef();
  const [loader, setLoader] = useState(false)
  const [inputdata, setInputData] = useState(defaultValue);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleChange = ({ currentTarget: input }) => {
    setInputData({ ...inputdata, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('/api/contact/submit', {...inputdata , recaptchaToken});
      console.log(res.data);
      setRecaptchaToken(null)
      setInputData(defaultValue)
      successToast('Form submited successfully')
    } catch (error) {
      errorToast(error?.response?.data?.message ||  error?.message);
      console.error(error);
    }

    recaptchaRef.current.reset();
    setLoader(false)
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const isSubmitDisabled = !recaptchaToken;

  return (
    <div className='body-main'>
      <div className='contact-sec'>
        <div className='container'>
          <div className='section-title'>
            <h2>Contact us</h2>
          </div>
        </div>
        <div className='contf'>
          <div className='cont-form'>
            <form onSubmit={handleSubmit}>
              <div className="row form1">
                <div className="col-md-6">
                  <label for="Full Name" className="form-label">Full Name</label>

                  <input type="text" required className="form-control contact-form" onChange={handleChange} value={inputdata.name} placeholder="Name" name="name" />

                 

                </div>
                <div className="col-md-6">
                  <label for="Email" className="form-label">Email Address</label>
                  <input type="email" required className="form-control contact-form" onChange={handleChange} value={inputdata.email} placeholder="Enter email" name="email" />
                </div>
              </div>
              <div className="row form1 mt-4">
                <div className="col-md-12">
                  <label for="Subject" className="form-label">Subject</label>
                  <input type="text" required className="form-control contact-form" onChange={handleChange} value={inputdata.subject} placeholder="Subject" name="subject" />
                </div>
              </div>
              <div className="row form1 mt-4">
                <div className="col-md-12">
                  <label for="MESSAGE" className="form-label">Message</label>
                  <textarea className="form-control contact-form" rows="5" id="comment" name="message" placeholder='Message' value={inputdata.message} onChange={handleChange}>{inputdata.message}</textarea>
                </div>
              </div>
              <div className="row form1 mt-4">
                <div className="col-md-12 d-flex justify-content-center">
                  <ReCAPTCHA
                    sitekey={process.env.RECAPTCHA_SECRET}
                    onChange={handleRecaptchaChange}
                    required
                    ref={recaptchaRef}
                  />
                </div>
              </div>
              <div className='cont-subt'>
                <button type="submit" className="btn hero-btn" disabled={isSubmitDisabled}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contactus;