import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom"
import { TableContainer } from "@material-ui/core";


const EditSoloChallengeModal = ({
  show,
  challenge = {},
  handleModalClose,
  getChallenges,
  isManual = false
}) => {
  const [loading, setLoading] = useState(false);
  const [player1,setPlayer1] = useState({})
  const [checked,setChecked] = useState(false)

const handleForceWin = async ()=>{
  setLoading(true);
  try {
    await axios.post("/api/auth/forcewinsolochallenge", {
      userid: challenge.player_1_id,
      challengeid: challenge._id,
      cashoutmoney: challenge.totalwager
    });
  } catch (error) {
    console.error("Error occurred:", error);  // Log the error
  } finally {
    setLoading(false);  // Always stop loading, even if there's an error
    handleModalClose(true);
  }
}
const handleForceLose = async ()=>{
  setLoading(true);
  try {
    await axios.post("/api/auth/forcelosesolochallenge", {
      userid: challenge.player_1_id,
      challengeid: challenge._id
    });
  } catch (error) {
    console.error("Error occurred:", error);  // Log the error
  } finally {
    setLoading(false);  // Always stop loading, even if there's an error
    handleModalClose(true);
  }
}
const handleForceDelete = async ()=>{
  setLoading(true);
  try {
    await axios.post("/api/auth/forcedeletechallenge", {
      challengeid: challenge._id
    });
  } catch (error) {
    console.error("Error occurred:", error);  // Log the error
  } finally {
    setLoading(false);  // Always stop loading, even if there's an error
    handleModalClose(true);
  }
}

  useEffect(() => {
   if(Object.keys(challenge).length){
    setPlayer1(challenge.player_1[0])
   }
  }, [challenge]);

return (
  <div className="EditUserModal">
    <Drawer
      variant="temporary"
      open={show}
      anchor='right'
      onClose={()=>handleModalClose(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 500 },
      }}
    >
      <div className="drawer-header">
        <h2>Solo Showdown details</h2>
        <div className="cross-icon">
          <Button
            className="none-bg fixed-position"
            onClick={()=>handleModalClose(false)}
          >
            <i class="fa-solid fa-xmark" style={{fontSize:"19px"}}></i>            
          </Button>
        </div>
      </div>
      <div className="player-1">
        <div className="player-1-info">
          Challenger's details :
        </div>
        <Grid container spacing={2} className="grid-1">
          <Grid xs={4} style={{height:"50px"}} className="inner-grid-1">
            Name 
          </Grid>
          <Grid xs={8} className="inner-grid-2">
            {Object.keys(challenge).length ?challenge.player_1[0].name :""}
          </Grid>
          <Grid xs={4} style={{height:"50px"}} className="inner-grid-1">
            Terms 
          </Grid>
          <Grid xs={8} className="inner-grid-2">
            {Object.keys(challenge).length ?challenge.player_1[0].text :""}
          </Grid>
          <Grid xs={4} style={{height:"50px"}} className="inner-grid-1">
            Game of choice 
          </Grid>
          <Grid xs={8} className="inner-grid-2">
            {Object.keys(challenge).length ?challenge.player_1[0].gamechoice :""}
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        spacing={2}
        style={{
          padding: '15px'
        }}
      >
        <Grid item xs={12} className="Selection-Header">
          <h4>Set Challenge Results</h4>
        </Grid>

        <Grid item xs={5} className="Selection-Body">
          <Button disabled={loading} variant="success" className="force-win" style={{ width: '100%' }}
            onClick={handleForceWin}
          >
            {loading?"Loading...":"Force Win"}
          </Button>
        </Grid>
        <Grid item xs={4} className="Selection-Body">
          <Button disabled={loading} variant="danger" className="force-lose" style={{ width: '100%' }}
            onClick={handleForceLose}
          >
            {loading?"Loading...":"Force Lose"}
          </Button>
        </Grid>
        <Grid item xs={3} className="Selection-Body" style={{padding: '10px'}}>
          <Button disabled={loading} variant="danger" className="force-delete"
            onClick={handleForceDelete}
          >
            {loading?"Loading...":"Delete"}
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  </div>
);
};

export default EditSoloChallengeModal;
