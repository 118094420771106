import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Card } from "./Card";
import "./Admin.css";
import logo from "../images/fulllogo_transparent.png"
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
export const Admin = () => {
  const activeLink = useLocation().pathname;
  const navigate = useNavigate();
  const [usercount, setUserCount] = useState();
  const [userdata, setuserdata] = useState([]);
  const [allchallenge, setAllchallenge] = useState([]);
  const [tokenid, setTokenid] = useState(localStorage.getItem("nftadmintoken"));
  const getuserdata = async () => {
    const res = await axios.post("/api/auth/getuserdata");
    setuserdata(res.data);
    setUserCount(res.data.length);
  };

  const logout = () => {
    localStorage.removeItem("nftadmintoken");
    navigate("/admin/login");
  };
  useEffect(() => {
    if (tokenid == null) {
      navigate("/admin/login");
    }
    // getuserdata();
    // getallchallenges();
  }, []);

  return (
    <div className="">
      <div className="">
        {/* <div style  = {{minHeight:"90vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
<Card user={usercount} title = "Users" subtitle = "Registered"/>
<Card user={allchallenge.length} title="Challenges"/>
<Card />
<Card/>
</div> */}
        <div className="sideNavbar">
          <nav>
            <div className="navcontent">
              <ul className="side-list">
                <div className="d-flex-center">
                  <Link className="logoimg" to="/">
                    <img alt="logo" src={logo} />
                  </Link>
                </div>
                <br />
                <li
                  className={`line-item ${
                    activeLink === "/admin/users" ? "active-bg" : ""
                  }`}
                >
                  <Link to="/admin/users" className="adt">
                    <i
                      className="fa fa-users iconA"
                      aria-hidden="true"
                      style={{ marginRight: "15px" }}
                    />
                    Users
                  </Link>
                </li>
                <li
                  className={`line-item ${
                    activeLink === "/admin/challenges" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/challenges">
                    <i
                      className="fa fa-gamepad iconA"
                      aria-hidden="true"
                      style={{ marginRight: "13px" }}
                    />
                    Challenges
                  </Link>
                </li>
                <li
                  className={`line-item ${
                    activeLink === "/admin/settings" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/settings">
                    <i
                      className="fa fa-cog iconA"
                      aria-hidden="true"
                      style={{ marginRight: "13px" }}
                    />
                    Settings
                  </Link>
                </li>
                <li
                  className={`line-item ${
                    activeLink === "/admin/coupon" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/coupon">
                    <i
                      className="fa fa-gift iconA"
                      aria-hidden="true"
                      style={{ marginRight: "11px" }}
                    />
                    Coupon Code
                  </Link>
                </li>
                <li
                  className={`line-item ${
                    activeLink === "/admin/monitorfunds" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/monitorfunds">
                    <i
                      className="fa fa-solid fa-money-bill"
                      aria-hidden="true"
                      style={{ marginRight: "11px" }}
                    />
                    Monitor Funds
                  </Link>
                </li>
                <li
                  className={`line-item ${
                    activeLink === "/admin/transactionlog" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/transactionlog">
                    <i
                      className="fa fa-exchange iconA"
                      aria-hidden="true"
                      style={{ marginRight: "11px" }}
                    />
                    Transaction Log
                  </Link>
                </li>
                <li className="logoutadmin line-item">
                  <Link
                    className="adt"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                  >
                    <i
                      className="fa fa-sign-out iconA"
                      aria-hidden="true"
                      style={{ marginRight: "15px" }}
                    />
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <div className="mobile-nav">
        {[false].map((expand) => (
          <Navbar key={expand} expand={expand}>
            <Container fluid>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
              >
                <Offcanvas.Header
                  closeVariant="white"
                  closeButton
                  className="admin-offcanvas-header"
                >
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  ></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="admin-offcanvas-body">
                  <div className="admin-logo">
                    <Link to="/">
                      <img alt="logo" src={logo}></img>
                    </Link>
                  </div>
                  <ul className="side-list">
                    <li
                      className={`line-item ${
                        activeLink === "/admin/users" ? "active-bg" : ""
                      }`}
                    >
                      <Link to="/admin/users" className="adt admin-users">
                        <i
                          className="fa fa-users iconA"
                          aria-hidden="true"
                          style={{ marginRight: "15px" }}
                        />
                        Users
                      </Link>
                    </li>
                     <li
                  className={`line-item ${
                    activeLink === "/admin/challenges" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/challenges">
                    <i
                      className="fa fa-gamepad iconA"
                      aria-hidden="true"
                      style={{ marginRight: "13px" }}
                    />
                    Challenges
                  </Link>
                </li>
                <li
                  className={`line-item ${
                    activeLink === "/admin/settings" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/settings">
                    <i
                      className="fa fa-cog iconA"
                      aria-hidden="true"
                      style={{ marginRight: "13px" }}
                    />
                    Settings
                  </Link>
                </li>
                  <li
                  className={`line-item ${
                    activeLink === "/admin/coupon" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/coupon">
                    <i
                      className="fa fa-gift iconA"
                      aria-hidden="true"
                      style={{ marginRight: "13px" }}
                    />
                    Coupon Code
                  </Link>
                </li>
                <li
                  className={`line-item ${
                    activeLink === "/admin/monitorfunds" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/monitorfunds">
                    <i
                      className="fa fa-solid fa-money-bill"
                      aria-hidden="true"
                      style={{ marginRight: "11px" }}
                    />
                    Monitor Funds
                  </Link>
                </li>
                <li
                  className={`line-item ${
                    activeLink === "/admin/transactionlog" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/transactionlog">
                    <i
                      className="fa fa-exchange iconA"
                      aria-hidden="true"
                      style={{ marginRight: "13px" }}
                    />
                    Transaction Log
                  </Link>
                </li>
                {/*<li
                  className={`line-item ${
                    activeLink === "/admin/disputes" ? "active-bg" : ""
                  }`}
                >
                  <Link className="adt" to="/admin/disputes">
                    <i
                      class="fa fa-exclamation-circle iconA"
                      aria-hidden="true"
                      style={{ marginRight: "15px" }}
                    ></i>
                    Disputes
                  </Link>
                </li> */}
                    {/* <li>
                  <a href="">Demo</a>
                </li>
                <br /> */}
                    <li className="logoutadmin line-item">
                      <Link
                        className="adt"
                        onClick={(e) => {
                          e.preventDefault();
                          logout();
                        }}
                      >
                        <i
                          className="fa fa-sign-out iconA"
                          aria-hidden="true"
                          style={{ marginRight: "15px" }}
                        />
                        Logout
                      </Link>
                    </li>
                  </ul>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </div>
  );
};
