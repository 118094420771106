import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom"


const EditChallengeModal = ({
  show,
  challenge = {},
  handleModalClose,
  getChallenges,
  isManual = false
}) => {
  const [loading, setLoading] = useState(false);
  const [player1,setPlayer1] = useState({})
  const [player2,setPlayer2] = useState({})
  const [winner,setWinner] = useState({})
  const [checked,setChecked] = useState(false)

  useEffect(() => {
   if(Object.keys(challenge).length){
    setPlayer1(challenge.player_1[0])
    setPlayer2(challenge.player_2[0])
   }
  }, [challenge]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { data } = await axios.post("/api/auth/manualReview", {
      _id:challenge._id,winner,playerwager:challenge?.playerwager
    });
    setLoading(false);
    if (data.success) {
      getChallenges(data.message);
      clearStates()
      handleModalClose();
    }
  };

  const clearStates = ()=>{
    setPlayer1({})
    setPlayer2({})
    setWinner({})
  }

  return (
    <div className="EditUserModal">
      {/* <Modal show={show} onHide={()=>{
       handleModalClose()
      }}>
        <Form onSubmit={submitHandler}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title contentClassName="challenge-heading">
              Manual review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
            <b>Declare winner by manual review.</b>
        <div key={`reverse-radio`} className="mb-3 mt-3">
          <Form.Check
            reverse
            label={player1?.name}
            name="group1"
            type={"radio"}
            value={challenge?.player_1_id}
            id={`reverse-radio-1`}
            onChange={(e)=>setWinner(e.target.value)}
          />
          <Form.Check
            reverse
            label={player2?.name}
            name="group1"
            type={"radio"}
            id={`reverse-radio-2`}
            value={challenge?.player_2_id}
            onChange={(e)=>setWinner(e.target.value)}

          />
        </div>
    </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{
        handleModalClose()
      }}>
              Close
            </Button>
            <Form.Group role="form">
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? "Loading..." : "Save Changes"}
              </Button>
            </Form.Group>
          </Modal.Footer>
        </Form>
      </Modal> */}

      <Drawer
          variant="temporary"
          open={show}
          anchor='right'
          onClose={handleModalClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 500 },
          }}
        >
          <div className="drawer-header">
            <h2>Challenge details</h2>
            <div className="cross-icon">
            <Button
            className="none-bg fixed-position"
            onClick={()=>handleModalClose()}>
        <i className="fa-solid fa-xmark" style={{fontSize:"19px"}}></i>            
         </Button>
            </div>
          </div>
         {!isManual ? <>
          <div className="player-1">
          <div className="player-1-info">
            Game link : 
          </div>
          <Grid container spacing={2} className="grid-1">
            <Grid item xs={12} style={{height:"50px"}} className="inner-grid-1">
              <Link to={Object.keys(challenge).length ?challenge.player_1[0]?.link :""}>{Object.keys(challenge).length ?challenge.player_1[0]?.link :""}</Link>
            </Grid>
          </Grid>
          <div className="player-1-info">
            Challenger's details :
          </div>
          <Grid container spacing={2} className="grid-1">
            <Grid item xs={4} style={{height:"50px"}} className="inner-grid-1">
              Name 
            </Grid>
            <Grid item xs={8} className="inner-grid-2">
              {Object.keys(challenge).length ?challenge.player_1[0].name :""}
            </Grid>
            <Grid item xs={4} style={{height:"50px"}} className="inner-grid-1">
              Terms 
            </Grid>
            <Grid item xs={8} className="inner-grid-2">
              {Object.keys(challenge).length ?challenge.player_1[0].text :""}
            </Grid>
            <Grid item xs={4} style={{height:"50px"}} className="inner-grid-1">
              Game of choice 
            </Grid>
            <Grid item xs={8} className="inner-grid-2">
              {Object.keys(challenge).length ?challenge.player_1[0].gamechoice :""}
            </Grid>
          </Grid>
          </div>
          <div className="player-2">

          <div className="player-2-info">
            Reciever's details :
          </div>
          <Grid container spacing={2} className="grid-2">
            <Grid item xs={4} style={{height:"50px"}} className="inner-grid-1">
              Name 
            </Grid>
            <Grid item xs={8} className="inner-grid-2">
              {Object.keys(challenge).length ?challenge.player_2[0]?.name :""}
            </Grid>
            <Grid item xs={4} style={{height:"50px"}} className="inner-grid-1">
              Terms 
            </Grid>
            <Grid item xs={8} className="inner-grid-2">
              {Object.keys(challenge).length ?challenge.player_2[0]?.text :""}
            </Grid>
            <Grid item xs={4} style={{height:"50px"}} className="inner-grid-1">
              Game of choice 
            </Grid>
            <Grid item xs={8} className="inner-grid-2">
              {Object.keys(challenge).length ?challenge.player_2[0]?.gamechoice :""}
            </Grid>
          </Grid>
          </div>
          </>:
         <div className="player-2-info" style={{padding:"10px"}}>
            Manual review :
            <Grid container spacing={2} className="grid-2">
            <Grid item xs={4} style={{height:"50px"}} className="inner-grid-1">
              Winner
            </Grid>
            <Grid item xs={8} className="inner-grid-2">
            <Form className="radio-form" onSubmit={submitHandler}>
              <div className='radio-options'>
          <Form.Check
            reverse
            label={player1?.name}
            name="group1"
            type={"radio"}
            value={challenge?.player_1_id}
            id={`reverse-radio-1`}
            onChange={(e)=>setWinner(e.target.value)}
          />
          <Form.Check
            reverse
            label={player2?.name}
            name="group1"
            type={"radio"}
            id={`reverse-radio-2`}
            value={challenge?.player_2_id}
            onChange={(e)=>setWinner(e.target.value)}

          />
          </div>
            <Form.Group role="form">
              <Button variant="primary" type="submit" disabled={loading} style={{marginRight:'8px',padding:"4px"}}>
                {loading ? "Loading..." : "Save"}
              </Button>
            </Form.Group>
          </Form>
            </Grid>
            </Grid>
          </div>
}
        </Drawer>
    </div>
  );
};

export default EditChallengeModal;
