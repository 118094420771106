import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import SocialMediaLinks from "../component/SocialMediaLinks";
import  "./Howtoplay.css";

const Howtoplay = () =>{
    const [gif1Playing, setGif1Playing] = useState(false);
    const [gif2Playing, setGif2Playing] = useState(false);
    const [gif3Playing, setGif3Playing] = useState(false);
    const [gif4Playing, setGif4Playing] = useState(false);

  const handleGif1Click = () => {
    setGif1Playing(!gif1Playing);
  };

  const handleGif2Click = () => {
    setGif2Playing(!gif2Playing);
  };
  
  const handleGif3Click = () => {
    setGif3Playing(!gif3Playing);
  };

  const handleGif4Click = () => {
    setGif4Playing(!gif4Playing);
  };

    return  (
    <section className="how-to-play">
        <div className="how-container">
            <div>
            <h2 className="instructions-heading">Sign Up</h2>
            <div className="instructions-content">
                <ol className="content-list">
                    <li>Click on the <Link to="/register">Sign Up</Link> button to create your account</li>
                    <li>Provide the required information, such as your username, email address, age, and password</li>
                    <li>Complete the registration process and verify your email</li>
                </ol>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">How to Deposit Funds</h2>
            <div className="instructions-content">
                <ol className="content-list">
                    <li>Hover over your avatar in the top right of the Homepage and click <Link to="/profile">Profile</Link></li>
                    <li>Navigate to the "Deposit/Payment" section of your GameWagrs account.</li>
                    <li>Enter the amount you with to deposit</li>
                    <li>Then click the PayPal button on the right</li>
                    <li>In the popup, enter you PayPal login information then click 'Confirm Purchase' at the bottom</li>
                    <li>The popup will close and the deposit will be reflected in your GameWagrs account.</li>
                </ol>
                <div className="center-gif">
                <div className="position-relative">
                    {gif1Playing ? (
                        <img src="./deposit-funds.gif" alt="GIF" onClick={handleGif1Click} />
                    ) : (
                        <>
                        <img src="./deposit-fund.png" alt="img" onClick={handleGif1Click} />
                        <button onClick={handleGif1Click} className="how-to-play-btn"><i class="fa fa-play" aria-hidden="true"></i></button>
                        </>
                    )}
                    
                    </div>
                </div>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Linking Your Overwolf Account</h2>
            <div className="instructions-content">
            <p>You MUST download the <a href="https://www.overwolf.com/app/Gamewagrs.com-GameWagrs">GameWagrs Validator</a> app in addition to linking your Overwolf account in your <Link to="/profile">Profile</Link>.</p>
            <ol className="content-list">
                <li>Sign In: After signing in, jump to the GameWagrs homepage</li>
                <li>Access Your Profile: Hover over your avatar in the top right</li>
                <li>Go to Linked Accounts: Select 'Profile' from the dropdown, and then click on 'Linked Accounts.'</li>
                <li>Connect Overwolf Account: Ready for the magic? Click on 'Connect Overwolf Account.'</li>
                <li>Hit Connect: click on 'Click here to connect.'</li>
                <li>Overwolf Login: A new window opens -  If you already have an Overwolf account, enter your details in the popup.</li>
                <li> * Remember, skip the Social Logins like Facebook, Google, Twitch, etc.</li>
            </ol>
            <div className="center-gif">
            <div className="position-relative">
                    {gif4Playing ? (
                        <img src="./play-challenge.gif" alt="GIF" onClick={handleGif4Click} />
                    ) : (
                        <>
                        <img src="./play-challenge.png" alt="img" onClick={handleGif4Click} />
                        <button onClick={handleGif4Click} className="how-to-play-btn"><i class="fa fa-play" aria-hidden="true"></i></button>
                        </>
                    )}
                    
                    </div>
            </div>
            <ol className="content-unordered-list" style={{paddingTop:"30px"}}>
                <li>Complete the challenge within the specified time frame or match requirements.</li>
            </ol>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Find and Join Public Challenges</h2>
            <div className="instructions-content">
                <ol className="content-list">
                    <li>Browse the list of challenges on the <Link to="/challengemarketplace">Challenge Marketplace</Link> to find specific games or creators you want to play against.</li>
                    <li>Select a challenge that suits your preferences in terms of game, wager amount, and opponent.</li>
                    <li>Read the challenge details, rules, and any additional information provided.</li>
                    <li>Click on the "Accept" button to participate in the challenge.</li>
                    <li>Once both parties have accepted, the challenge begins! Your <Link to="/ChallengeReceived">Challenge Status</Link> page will update with the each contestants progress as you play!</li>
                    <li>Once the challenge terms are met, the winner will be rewarded automatically!</li>
                </ol>
            </div>
            </div>
            {/* <div className="how-challenge mt-5">
            <div class="scrollmenu" id="instructions-head">
             <div>
                    <h2 className="instructions-two-heading">Individual Challenge(with stream link)</h2>
                    <div className="instructions-content">
                    <img src={gif2Playing ? './with-streamlink.gif' : './with-streamlink.png'} onClick={handleGif2Click}/>
                    </div>
                </div>
                <div>
                    <h2 className="instructions-two-heading">Sending and Receiving a Public Challenge</h2>
                    <div className="instructions-content">
                    <img src={gif3Playing ? './pub-challenge.gif' : './pub-challenge.png'} onClick={handleGif3Click}/>
                    </div>
                </div>
                </div>
            </div> */}
            <div className="mt-5">
                <h2 className="instructions-heading">Sending and Receiving a Public Challenge</h2>
                <div className="center-gif">
                <div className="position-relative">
                    {gif3Playing ? (
                        <img src="./pub-challenge.gif" alt="GIF" onClick={handleGif3Click} />
                    ) : (
                        <>
                        <img src="./pub-challenge.png" alt="img" onClick={handleGif3Click} />
                        <button onClick={handleGif3Click} className="how-to-play-btn"><i class="fa fa-play" aria-hidden="true"></i></button>
                        </>
                    )}
                    
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <h2 className="instructions-heading">Individual Challenge(with stream link)</h2>
                <div className="center-gif">
                    <div className="position-relative">
                    {gif2Playing ? (
                        <img src="./with-streamlink.gif" alt="GIF" onClick={handleGif2Click} />
                    ) : (
                        <>
                        <img src="./with-streamlink.png" alt="img" onClick={handleGif2Click} />
                        <button onClick={handleGif2Click} className="how-to-play-btn"><i class="fa fa-play" aria-hidden="true"></i></button>
                        </>
                    )}
                    
                    </div>
                </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Report Results and Disputes</h2>
            <div className="instructions-content">
                <ol className="content-unordered-list">
                    <li>If there are any disputes or disagreements, Please contact <a href="mailto:administrator@gamewagrs.com">administrator@gamewagrs.com.</a> or submit a ticket on or <a href="https://discord.com/invite/TzHGjNyACC">discord</a>.</li>
                    <li>We will also be reviewing any live stream link associated with the challenge manually by our moderators</li>
                </ol>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Withdraw Winnings</h2>
            <div className="instructions-content">
                <ol className="content-list">
                    <li>If you win a challenge, the winnings will be automatically transferred to your GameWagrs wallet.</li>
                    <li>Navigate to the "Cash Out" section of your <Link to="/profile">Profile</Link></li>
                    <li>Enter the amount you wish to withdraw</li>
                    <li>Click the PayPal button on the right</li>
                    <li>Enter your PayPal email address</li>
                    <li>You will receive and email from PayPal and your funds will be released to your PayPal account</li>
                </ol>
            </div>
            </div>
           
               {/* <div className="footer-social-media-wrapper header-icons">
                      <SocialMediaLinks />
                    </div> */}
        </div>
    </section>
    )
}

export default Howtoplay;