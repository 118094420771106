import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./Winner.css";
import axios from "axios";
import { Loader } from "../component/Loader";
import lost from "../../src/images/lost.png";
import ContentLoader from "react-content-loader";
import moment from "moment";

export const Loser = () => {
  const { id, index,challengeno } = useParams();
  const [images, setImages] = useState([]);
  const [aadil, setaadil] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loseid, setloseid] = useState("");
  const [playerwager, setPlayerWager] = useState(0);
  const [playersdecision, setPlayersDecision] = useState(null)
  const [currentUserdata, setcurrentUserdata] = useState([]);
  const storagedata = JSON.parse(localStorage.getItem("nftuser"));
  const [tabLoading, setTabLoading] = useState(false);
  const [usergamedata,setUserGameData]= useState([]);
  const [gameusers,setGameUsers] = useState([]);
  const [gameerr, setGameErr] = useState(null)
  const getlosser = async () => {
    setTabLoading(true);
    if (index === "loser") {
      return setLoader(false);
    }
    const res = await axios.post("/api/auth/winnerchallenge", {
      id: id,
      result: "declare",
    });
    if (res) {
      setPlayerWager((res.data[0].player_1[0].playerwager));
      setPlayersDecision(res?.data[0]?.players_decision)
      const playerAoverwolfUsername = res.data[0].player_1[0]?.userId?.overwolf?.sub;
      const playerBoverwolfUsername = res.data[0].player_2[0]?.userId?.overwolf?.sub;
      let tempusersData = [];
      tempusersData.push(res.data[0].player_1[0])
      tempusersData.push(res.data[0].player_2[0])
      setGameUsers(tempusersData);
      if(res?.data[0]?.owgamedata?.length > 0){
        const revOwData = [].concat(res?.data[0]?.owgamedata).reverse();
         setUserGameData(revOwData)
      } else {
        const challengeAcceptTime = moment(res?.data[0]?.Accepttime).unix() * 1000;
        const owdatainapimore = await axios.post("/api/auth/updateOwgameDataFromApi", {
          id: id,
          gameId :  10798,
          playerAoverwolfUsername:playerAoverwolfUsername,
          playerBoverwolfUsername:playerBoverwolfUsername,
          challengeAcceptTime:challengeAcceptTime
        });
        if (owdatainapimore?.data?.owgamedata?.length > 0) {
          const revOwData = [].concat(res?.data[0]?.owgamedata).reverse();
         setUserGameData(revOwData)
         } else {
          setGameErr(res?.data[0]?.message)
         }
      }
      setPlayerWager(res?.data[0]?.player_1[0]?.playerwager);
      setLoader(false);
      if (res?.data[0]?.loser) {
        Getcurrentuser(res?.data[0]?.loser);
      }
    }
  };

  const Getcurrentuser = async (loserid) => {
    const userres = await axios.post("/api/auth/getcurrentuser", {
      id: loserid,
    });

    setcurrentUserdata(userres.data);
    setTabLoading(false);
  };

  useEffect(() => {
    getlosser();
  }, [id, index, loader]);

  const getNumberofMatches = (text) => {
    const str = text.split(":").pop();
    const numbers = str.match(/\d+(\.\d+)?/g);
    return numbers[0];
   }

  return (
    <>

      <div className="container">
      {tabLoading? <div className="inner-space-wrap-status">
          <div className="section-title">
            <div className="user-status d-flex-center">
            <ContentLoader
                            height={60}
                            speed={1}
                            backgroundColor={"#333"}
                            foregroundColor={"#999"}
                            viewBox="0 0 200 55"
                          />
            </div>
          </div>
          <div className="fund-status">
            <div className="available-funds">
              <div className="result-money lost text-center">
              <ContentLoader 
                          speed={1}
                          width={180}
                          height={50}
                          viewBox="0 0 160 70"
                          backgroundColor={'#333'}
                          foregroundColor={'#999'}
                          >
                          <rect x="0" y="20" rx="3" ry="3" width="160" height="15" /> 
                          </ContentLoader>
              </div>
              <div className="updated-funds text-center">
              <ContentLoader 
                          speed={1}
                          width={180}
                          height={50}
                          viewBox="0 0 160 70"
                          backgroundColor={'#333'}
                          foregroundColor={'#999'}
                          >
                          <rect x="0" y="20" rx="3" ry="3" width="160" height="15" /> 
                          </ContentLoader>
              </div>
            </div>
            <div
              className="col-md-12 "
              style={{ color: "white", marginTop: "48px" }}
            >
              <div className="row">
                <div className="col-md-4 text-end">
                  <p className="result">
                  <ContentLoader 
                          speed={1}
                          width={180}
                          height={50}
                          viewBox="0 0 160 70"
                          backgroundColor={'#333'}
                          foregroundColor={'#999'}
                          >
                          <rect x="0" y="20" rx="3" ry="3" width="160" height="15" /> 
                          </ContentLoader>
                  </p>
                </div>
                <div className="col-md-8 d-flex text-center">
                <ContentLoader
                            height={60}
                            speed={1}
                            backgroundColor={"#333"}
                            foregroundColor={"#999"}
                            viewBox="0 0 200 55"
                          />
                <ContentLoader
                            height={60}
                            speed={1}
                            backgroundColor={"#333"}
                            foregroundColor={"#999"}
                            viewBox="0 0 200 55"
                          />
                <ContentLoader
                            height={60}
                            speed={1}
                            backgroundColor={"#333"}
                            foregroundColor={"#999"}
                            viewBox="0 0 200 55"
                          />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="challenge-btns"
              style={{
                marginTop: "80px",
              }}
            >
              <div className="row ">
                <div className="col-md-4 col-sm-12 text-end mb-2">
                <ContentLoader
                            height={60}
                            speed={1}
                            backgroundColor={"#333"}
                            foregroundColor={"#999"}
                            viewBox="0 0 200 55"
                          >
                          <rect x="0" y="20" rx="3" ry="3" width="200" height="25" /> 
                         
                          </ContentLoader>
                </div>
                <div className="col-md-4 col-sm-12 text-center mb-2">
                <ContentLoader
                            height={60}
                            speed={1}
                            backgroundColor={"#333"}
                            foregroundColor={"#999"}
                            viewBox="0 0 200 55"
                          >
                          <rect x="0" y="20" rx="3" ry="3" width="200" height="25" /> 
                         
                          </ContentLoader>
                </div>
                <div className="col-md-4 col-sm-12 text-start">
                <ContentLoader
                            height={60}
                            speed={1}
                            backgroundColor={"#333"}
                            foregroundColor={"#999"}
                            viewBox="0 0 200 55"
                          >
                          <rect x="0" y="20" rx="3" ry="3" width="200" height="25" /> 
                         
                          </ContentLoader>
                </div>
              </div>
            </div>
          </div>
        </div> : <>
        <div className="inner-space-wrap-status">
          <div className="section-title">
            <div className="user-status d-flex-center">
              <h2>You Lost!</h2>
              <img src={lost} alt="lost-img" className="lost-img" />
            </div>
          </div>
          <div className="fund-status">
            <div className="available-funds">
              <div className="result-money lost text-center">
                -${playerwager?.toFixed(2)}
              </div>
              <div className="updated-funds-title text-center">
                New Avaliable Funds: <span className="updated-funds">${currentUserdata?.available_fund?.toFixed(2)}</span>
              </div>
            </div>
            <div className="challenge-no text-center">
                Challenge No.: <span style={{ fontFamily:"Roboto"}}>{challengeno}</span>
              </div>
            <div
              className="col-md-12 "
              style={{ color: "white", marginTop: "48px" }}
            >
             
             {playersdecision !== "manual" ? <div className="row">
                {usergamedata.length > 0 ? (
                  <>
                    <div className="col-md-4 text-end">
                      <p className="result">Results:</p>
                    </div>
                    <div className="col-md-8 d-flex text-center flex-wrap gap-4">
                      {usergamedata.slice(0, getNumberofMatches(gameusers[0].text)).map((item, index) => (
                        <div className="game-wrapper">
                          <span className="live-res-game">Game {index + 1}</span>
                          {gameusers[0]?.userId?.overwolf.sub == item?.winner?.overwolfUsername ? <span className="live-res-user">{gameusers[0]?.name}</span> : <span className="live-res-user">{gameusers[1]?.name}</span>}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <p className="decision-manual">{gameerr =="Cannot read properties of undefined (reading 'steamId')"? "We are experiencing some technical issues. Please try again later.":gameerr}</p>
                )}
              </div>:null}
              <div className="row">
                {playersdecision == "manual" ? (
                  <>
                   <p className="decision-manual">You have declared yourself Loser manually</p>
                  </>
                ):(
                  null
                )}
              </div>
              </div>
            </div>
          <div className="col-md-12">
            <div
              className="challenge-btns"
              style={{
                marginTop: "80px",
              }}
            >
              <div className="row ">
                <div className="col-md-4 col-sm-12 text-end mb-2">
                  <Link to="/ChallengeSomeone">
                    <button className="challenge-actions">
                      Challenge Again
                    </button>
                  </Link>
                </div>
                <div className="col-md-4 col-sm-12 text-center mb-2">
                  <Link to="/ChallengeSomeone">
                    <button className="challenge-actions">
                      Public Challenges
                    </button>
                  </Link>
                </div>
                <div className="col-md-4 col-sm-12 text-start">
                  <Link to="/profile/4">
                    <button className="challenge-actions deposit">
                      Deposit Funds
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> </>}
      </div>
    </>
  );
};