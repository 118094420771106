import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import "./Home.css";
// import Roadmap from "./Roadmap";
import Features from "./Features";
// import img1 from "../Edited/1.png";
// import img2 from "../Edited/2.png";
// import img3 from "../Edited/3.png";
// import mainimg from "../Edited/Group1.png";
import Rules from "./Rules";
import TopRecWinner from "./TopRecWinner";
import Partners from "./Partners";
import mainimg1 from '../images/Rectangle8.webp'
import mainimg2 from '../images/Rectangle7.webp'

import bottomimg from "../images/public-challengers.webp";
import { PublicChallenge } from "../Pages/PublicChallenge";

const Home = () => {
  const [userdata, setUserdata] = useState([]);
  const [newuserdata, setnewuserdata] = useState([]);
  const [searchfilter, setsearchfilter] = useState([]);
  const [clickeduser, setclickeduser] = useState("");
  const [targetname, settargetname] = useState("");
  const [runfun, setrunfun] = useState(true);
  const loginUserData = JSON.parse(localStorage.getItem("nftuser"));
  const navigate = useNavigate();
  async function getuserdata() {
    const res = await axios.post("/api/auth/getuserdata");
    setUserdata(res.data);
    const localdata = JSON.parse(localStorage.getItem("nftuser"));
    userdata.sort((a, b) => a.username.localeCompare(b.username));
    const filtereduser = userdata.filter((items, index) => {
      return items._id !== localdata._id;
    });
    setnewuserdata(filtereduser);
    setsearchfilter(filtereduser);
  }

  const getrecieved = async () => {
    // const newres = await axios.post("/api/auth/challengedata", {
    //   id: data._id,
    //   Accept: true,
    //   result: "pending",
    // });
    // newres.data.map((item, index) => {
    //   console.log(new Date(item.createdAt).getTime() + 259200000);
    // });
  };
  
  // if(runfun){
  useEffect(() => {
    getuserdata();
    getrecieved();
  }, []);
  // }

  const handleuserclick = async (e) => {
    console.log(e);
    setclickeduser(e.target.name);
    settargetname(e.target.value);
    console.log(targetname);
    console.log(clickeduser);
  };

  const handleSearch = (event) => {
    setrunfun(false);
    let keyword = event.target.value;
    const result = newuserdata.filter((user) => {
      return user.username.toLowerCase().startsWith(keyword.toLowerCase());
    });

    setsearchfilter(result);
  };

  return (
    <>
      {/* hero section :: STRAT*/}
      <section className="hero-banner">
          <div className="container">
            <div className="row">
            <div className="col-lg-2"></div>
              <div className="col-lg-8 text-center">
                <h1 className="title">The easiest way to profit <br/> from gaming</h1>
                <p className="desc-para desc-download-para">Download Our App And Challenge Yourself For <br/> Instant Profits! Start Making Profits From The Games <br/> You Love To Play!</p>
              
                <div className="banner-btn">
                  <Link to="/register" className="btn btn-primary primary-btn-linear-bg">
                    Join Now
                  </Link>
                </div> 
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>
      </section>


      {/* <div className="card-section">
        <div className="container">
          <div className="row card-main">
            <div className="col-lg-6 order2">
              <div className="card-cont">
                <h2>Make Every Game Count</h2>
                <p>
                
                </p>
                <p>
                
                </p>
                <p>
                
                </p>
                <p>
                
                </p>
                <div className="btn-main ">
                <Link to="/register">
                    <button className="hero-btn1">Join Now</button>
                  </Link>
                  <Link to="/contact-us">
                    <button className="hero-btn">Contact</button>
                  </Link>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 order1">
              <div className="card-img">
                <img className="hvr-grow" src={mainimg1} alt="img" />
                <img className="hvr-grow1" src={mainimg2} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* hero section :: END*/}
      
      <section className="rules-feature-bg">
        {/* TopRecWinner section :: STRAT*/}
        <TopRecWinner />
        {/* TopRecWinner section :: END*/}
      {/* Rules section :: STRAT*/}
      <Rules/>
      {/* Rules section :: END*/}
      {/* Featuers section :: START*/}
        <Features />
        </section>
        
        <section className="Challenge-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className='section-title new-title-1 '>
                  <span>LIVE</span> Challenges! 
                </h2>
                <span className="sub-title-1">You can always accept challenges for <span>free!</span></span>
                <PublicChallenge homepage={{ isOpen:true }}/>
              </div>
            </div>
          </div>
       
      {/* Featuers section :: END*/}
     
      {/* Challenge section :: START*/}
      {/* <section className="features-space">
            <div className="container">
                
            </div>
      </section> */}
      
        <div className="container" style={{marginTop:'92px'}}>
              <div className="row profit-sec-center">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 features-profit-title">
                      <h3>Profit from your <span className="feature-blue-text">passion</span></h3>
                      <div className="profit-text-center">
                      <p className="features-content text-start" >We want to give every gamer a chance to profit from the games they play everyday. No “RNG”, No “odds”, no <br/>backing out.</p>
                      <p className="features-content text-start">No need to hustle for subscribers or travel for cash tournaments. Simply start a Solo Showdown, showcase your <br/>skills, and earn money for every win, goal, elimination, high score, and more!</p>
                      {!loginUserData ? <div className="btn btn-primary primary-btn-linear-bg" onClick={() => navigate('/register')}>
                          Sign up now!
                      </div> : null}
                      </div>
                  </div>
              </div>
              <div className="col-md-12 text-center device-img-top">
                <img loading="lazy" src="./devices.svg" className="w-100 rules-infographic" alt="img"/>
            </div>
          <div className="row justify-content-center add-start-space">
            <div className="col-md-10 text-center">
              <h2 className="section-title">
                  Start <span className="homepage-blue-platform-text">your</span> first challenge
              </h2>
              <p className="desc-para text-center">Prove your worth and challenge your favorite Twitch and Kick streamers to a 1v1 "micro-tournament"! If you come out on top, you'll be rewarded for your efforts with the tournament payout! So why not put your skills to the test and see if you have what it takes to win?</p>
              <p className="desc-para text-center">Challenge your friends or favorite streamers today!</p>
              <Link to="/ChallengeSomeone" className="btn btn-primary primary-btn-linear-bg">
                Commence Challenge
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Challenge section :: END*/}
            {/* how it work section :: STRAT*/}
            <section className="work-section rule-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title">
                  The <span className="homepage-blue-platform-text">gamewagrs</span> platform
              </h2>
              {/* <p className="desc-para max-w-750 text-center">Once you make a Challenge wager, you'll have the opportunity to challenge other players and win their cash bet. At GameWagrs, it's not about traditional sports betting; it's about staking on your own gaming abilities in the video game genre of your choice. Whether you're a master of first-person shooters, strategy games, or anything in between, GameWagrs provides a platform where you can double down on your gaming skills and compete for exciting cash prizes.</p> */}
            </div>
            <div className="col-md-12 text-center">
                <img loading="lazy" src="./Rules-Infographic-Background.webp" className="w-100 rules-infographic" alt="img"/>
            </div>
            <div className="col-md-12 platform-sec">
              <p className="desc-para max-w-750 text-center">In the unlikely event of a dispute via the concession system, both players have the option to provide a link to a VOD or stream of the challenge. Our team of experienced moderators will carefully review the provided footage to verify the outcome, ensuring a fair result is achieved.</p>
              <p className="desc-para max-w-750 text-center"> Our 3-way concession system to determine challenge winners and losers is in place to foster a secure and equitable environment for all players to fully enjoy their gaming experience. Begin your journey today and put your skills to the test with confidence, knowing that fairness and integrity are upheld.</p>
            </div>
          </div>
        </div>
      </section>

      <section>
      <div className="container founder-top">
        <div className="row">
          <h2 className="section-title">
              <span className="homepage-blue-platform-text">gamewagrs</span> founders
          </h2>
          <div className="col-md-4 col-12 mb-4 text-center">
              <img loading="lazy" src="./AndrewCard.png" className="founders-images" alt="img"/>
          </div>
          <div className="col-md-4 col-12 mb-4 text-center">
              <img loading="lazy" src="./NicoCard.png" className="founders-images" alt="img"/>
          </div>
          <div className="col-md-4 col-12 mb-4 text-center">
              <img loading="lazy" src="./KevanCard.png" className="founders-images" alt="img"/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 col-12 mb-4 text-center"></div>
          <div className="col-md-4 col-12 mb-4 text-center">
              <img loading="lazy" src="./AndrewHCard.png" className="founders-images" alt="img"/>
          </div>
          <div className="col-md-4 col-12 mb-4 text-center">
              <img loading="lazy" src="./AlejoCard.png" className="founders-images" alt="img"/>
          </div>
          <div className="col-md-2 col-12 mb-4 text-center"></div>
        </div>
      </div>
      </section>
      {/* founders section :: END*/}
      <Partners/>
      {/* roadmap section :: STRAT*/}
      {/* <Roadmap /> */}
      {/* roadmap section :: END*/}
    </>
  );
};

export default Home;
