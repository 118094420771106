import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";

const EditUserModal = ({
  show,
  userData,
  handleModalClose,
  getUserData,
}) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVerified, setVerified] = useState(false);
  useEffect(() => {
    setUserName(userData.username);
    if(userData.verified_at)setVerified(true)
  }, [userData.username]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { data } = await axios.post("/api/auth/updateUserData", {
      username: userName,
      password: password,
      _id: userData._id,
      verified:isVerified
    });
    setLoading(false);
    if (data.status) {
      getUserData();
      clearStates()
      handleModalClose(true);
    }
  };

  const DeleteUser = async () => {
    setLoading(true);
    console.log("Deleting");
    await axios.post("/api/auth/deleteuser", {_id: userData._id});
    console.log("Deleted");
    clearStates();
    handleModalClose(true);
    setLoading(false);
  }
  const clearStates = () =>{
    setUserName("")
    setPassword("")
    setVerified(false)
  }
  return (
    <div className="EditUserModal">
      <Modal show={show} onHide={()=>{
        clearStates()
        handleModalClose(false)
      }}>
        <Form onSubmit={submitHandler}>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title contentClassName="challenge-heading">
              Update User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                autoComplete={false}
                style={{backgroundColor:"#FFFFFF",padding:"10px"}}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                style={{backgroundColor:"#FFFFFF",padding:"10px"}}
                
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex" controlId="formSwitch">
              <Form.Label>User Verified : </Form.Label>
              <Form.Check
                type="checkbox"
                className="checkbox"
                checked={isVerified}
                onChange={(e) => {
                  console.log(e.target.checked)
                  setVerified(e.target.checked)}}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" size="sm" className="me-auto" onClick={()=>{
              DeleteUser()
            }}>
              {loading?"Loading..." : "Delete"}
            </Button>
            <Button variant="secondary" onClick={()=>{
              clearStates()
              handleModalClose(false)
            }}>
              Close
            </Button>
            <Form.Group role="form">
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? "Loading..." : "Save Changes"}
              </Button>
            </Form.Group>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default EditUserModal;
